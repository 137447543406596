import React from 'react';
import { saveAs } from 'file-saver';
import { Button } from 'primereact/button';
import { api } from '../../lib/api';
import { getJobListExport } from '../../actions/cafm/JobActions';

export default function DataTableExport({
  exportData: { extraParamList, getAllDataFn, exportFileName = 'xlsx' },
  multipleColumns,
  paramList,
}) {
  const getData = async () => {
    const columnList = multipleColumns.map((e) => e.name);
    const params = { params: { ...paramList, ...extraParamList, isExport: 1 } };
    const dataList = await getAllDataFn(params);
    const finalList = dataList.map((e) => {
      let newObj = {};
      columnList.map((columnName) => {
        newObj[columnName] = e[columnName];
      });
      return newObj;
    });
    return finalList;
  };
  const exportExcel = () => {
    import('xlsx').then(async (xlsx) => {
      const data = await getData();
      const workSheet = xlsx.utils.json_to_sheet(data);
      const workBook = { Sheets: { data: workSheet }, SheetNames: ['data'] };
      const excelBuffer = await xlsx.write(workBook, {
        bookType: 'xlsx',
        type: 'array',
      });
      saveAsExcelFile(excelBuffer, exportFileName);
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  };

  return (
    <Button
      icon={<i className={`pi pi-download`} style={{ fontSize: 20 }} />}
      style={{ width: '3.4rem', height: '3.4rem', justifyContent: 'center' }}
      severity="primary"
      aria-label="Export"
      onClick={exportExcel}
      data-tooltip-id="my-tooltip"
      data-tooltip-content="Export jobs"
      data-tooltip-place="top-end"
    />
  );
}
