import React from 'react';
import { Button } from 'primereact/button';

const CancelBtn = ({ fn, btnText = '' }) => {
  return (
    <Button
      icon={<i className={'fas fa-times'} style={{ fontSize: 13, marginRight: 3 }} />}
      type="button"
      label={btnText}
      severity={'secondary'}
      className="align-self-right ml-1"
      onClick={fn}
      raised
    />
  );
};
export default CancelBtn;
