import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import _ from 'lodash';

const TableActionMenu = ({
  permissions = {},
  disabled = false,
  actionsFn,
  otherOptions = [],
  selectedIds = [],
  paramsWithFilter,
}) => {
  const menuRight = useRef(null);
  const items = [
    {
      permission: permissions?.isTopDuplicate,
      label: 'Duplicate',
      icon: 'pi pi-fw pi-copy',
      command: () => actionsFn[0](selectedIds, paramsWithFilter),
    },
    {
      permission: permissions?.isTopEdit,
      label: 'Edit',
      icon: 'pi pi-fw pi-pencil',
      command: () => actionsFn[1](selectedIds, paramsWithFilter),
    },
    { label: 'Delete', icon: 'pi pi-fw pi-trash', command: () => actionsFn[2](selectedIds, paramsWithFilter) },
    ...(Array.isArray(otherOptions)
      ? otherOptions.map((item) => ({ ...item, command: () => item.command(selectedIds, paramsWithFilter) }))
      : []),
  ]
    .filter((item) => item.permission !== false)
    .map((item) => ({
      ...item,
      icon: item.icon ? <i className={`${item.icon} mr-2`} style={{ fontSize: 12 }} /> : null,
      disabled: item.disabled || false,
    }));

  return (
    <>
      <Menu
        model={items}
        popup
        ref={menuRight}
        id="popup_menu_right"
        popupAlignment="left"
        style={{ fontSize: 13, width: 'auto' }}
        autoZIndex
      />
      <Button
        icon={<i className="pi pi-ellipsis-v m-0 p-0" style={{ fontSize: 16, fontWeight: 'bold' }} />}
        className={`${disabled && 'p-disabled'}`}
        style={{ height: '3.5rem' }}
        onClick={(event) => menuRight.current.toggle(event)}
        aria-controls="popup_menu_right"
        aria-haspopup
      />
    </>
  );
};

TableActionMenu.propTypes = {};

export default TableActionMenu;
