import moment from 'moment';

const calculateHoursByDate = (startAt, departureAt) => {
  const startDate = startAt ? moment(startAt, 'DD/MM/YYYY HH:mm').startOf('m') : undefined;
  const departureDate = departureAt ? moment(departureAt, 'DD/MM/YYYY HH:mm').startOf('m') : undefined;

  let n = '00:00';
  if (startDate && departureDate) {
    const mins = departureDate.diff(startDate, 'minutes');
    const hours = parseInt(mins / 60);
    const minsfinal = parseInt(mins % 60);
    n = hours.toString().padStart(2, '0') + ':' + minsfinal.toString().padStart(2, '0');
  }
  return n;
};

function titleCase(str) {
  try {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  } catch (e) {
    return '';
  }
}

function sortName(str) {
  try {
    let splitStr = str.toUpperCase().split(' ');
    splitStr = splitStr[0].charAt(0) + splitStr[splitStr.length - 1].charAt(0);
    return splitStr;
  } catch (e) {
    return '';
  }
}

const validateEmail = (email) => {
  if (email && email.length > 0) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
  } else {
    return false;
  }
};

const dateCustomFormat = (date, fromFormat = 'YYYY-MM-DD HH:mm:ss', toFormat = 'YYYY-MM-DD HH:mm:ss') => {
  return moment(date, fromFormat).isValid() ? moment(date, fromFormat).format(toFormat) : null;
};

const formatDaysInFilters = (
  filters = [],
  filds = [],
  dateStartFolmat = 'YYYY-MM-DD 00:00:00',
  dateEndFormat = 'YYYY-MM-DD 23:59:59',
) => {
  return filters.map((e) => {
    if (filds.includes(e.filterField)) {
      const isBetween = e.fieldComparisonType === 'between';
      return {
        fieldComparisonType: 'between',
        filterField: e.filterField,
        filterValue: [
          moment(moment(isBetween ? e.filterValue[0] : e.filterValue).format(dateStartFolmat)).toISOString(),
          moment(moment(isBetween ? e.filterValue[1] : e.filterValue).format(dateEndFormat)).toISOString(),
        ],
      };
    } else {
      return e;
    }
  });
};

function getTableName(paramA, paramB) {
  if (!paramB || typeof paramB !== 'object' || Array.isArray(paramB)) return paramA;
  const values = Object.values(paramB)
    .filter((v) => v != null)
    .join('-');
  return values ? `${paramA}-${values}` : paramA;
}

function mergeArrays(arr1, arr2, key) {
  const mergedArray = [...arr1, ...arr2];
  const uniqueObjectsMap = new Map();
  mergedArray.forEach((item) => {
    uniqueObjectsMap.set(item[key], item);
  });
  const uniqueArray = Array.from(uniqueObjectsMap.values());
  return uniqueArray;
}

export {
  calculateHoursByDate,
  titleCase,
  validateEmail,
  sortName,
  dateCustomFormat,
  formatDaysInFilters,
  getTableName,
  mergeArrays,
};
