import { getPriorityOptions } from '../options';

export const FILTER_SITES = {
  name: {
    value: 'name',
    headerName: 'name',
    type: 'like',
  },
  address: {
    value: 'addressLine1',
    headerName: 'addressLine1',
    type: 'like',
  },
  created: {
    value: 'createdAt',
    headerName: 'createdAt',
    type: 'date',
  },
};

export const FILTER_ASSETS = {
  assetnumber: {
    value: 'reference',
    headerName: 'reference',
    type: 'number',
  },
  name: {
    value: 'name',
    headerName: 'name',
    type: 'like',
    array: 'assets',
  },
  quantity: {
    value: 'quantity',
    headerName: 'quantity',
    type: 'number',
  },
  location: {
    value: 'locationId',
    headerName: 'locationName',
    type: 'like',
    array: 'locations',
  },
  model: {
    value: 'modelId',
    headerName: 'manufacturerName',
    type: 'string',
    array: 'manufacturers',
    nestedArray: 'Model',
  },
};

export const FILTER_TASK_CATEGORIES = {
  name: {
    value: 'name',
    type: 'string',
    headerName: 'name',
    // array: 'taskCategories',
  },
  description: {
    value: 'description',
    type: 'string',
    headerName: 'description',
    // array: 'taskCategories',
  },
};

export const FILTER_LOCATIONS = {
  name: {
    value: 'name',
    type: 'like',
    array: 'locations',
  },
  floor: {
    value: 'type',
    type: 'number',
    array: 'locations',
  },
  // // parent: {
  // //   value: 'locationId',
  // //   type: 'string',
  // //   array: 'locations',
  // // },
  disabled: {
    value: 'disabled',
    type: 'boolean',
    boolArray: ['yes', 'no'],
  },
};

export const FILTER_TASKS = {
  name: {
    value: 'name',
    type: 'string',
    array: 'tasks',
  },
  'Repeat Every': {
    value: 'repeatEvery',
    type: 'number',
  },
  'Repeat Period': {
    value: 'repeatPeriod',
    type: 'string',
    array: 'tasks',
  },
  'Labour Required': {
    value: 'labourRequired',
    type: 'number',
  },
  'Labour Period': {
    value: 'labourPeriod',
    type: 'string',
    array: 'tasks',
  },
  Compliance: {
    value: 'taskComplianceId',
    type: 'string',
    array: 'taskCompliances',
  },
  'Is Regulatory': {
    value: 'isRegulatory',
    type: 'boolean',
    boolArray: ['yes', 'no'],
  },
};

export const FILTER_MANUFACTURERS = {
  name: {
    value: 'name',
    headerName: 'name',
    type: 'string',
  },
  description: {
    value: 'description',
    headerName: 'description',
    type: 'string',
  },
};

export const FILTER_PROBLEM_TOPICS = {
  name: {
    value: 'name',
    type: 'string',
    array: 'problemTopics',
  },
  'resolve minutes': {
    value: 'resolveMinutes',
    type: 'number',
  },
  'acknowledge minutes': {
    value: 'acknowledgeMinutes',
    type: 'number',
  },
  description: {
    value: 'description',
    type: 'string',
    array: 'problemTopics',
  },
  'issue category name': {
    value: 'id',
    type: 'string',
    array: 'problemCategoryAll',
    label: 'name',
  },
  enabled: {
    value: 'isEnabled',
    type: 'boolean',
    boolArray: ['enabled', 'disabled'],
  },
};

export const FILTER_PROBLEM_CATEGORIES = {
  name: {
    value: 'name',
    type: 'string',
    array: 'problemCategories',
  },
  enabled: {
    value: 'isEnabled',
    type: 'boolean',
    boolArray: ['enabled', 'disabled'],
  },
};

export const FILTER_MODELS = {
  name: {
    value: 'name',
    type: 'string',
  },
  description: {
    value: 'description',
    type: 'string',
  },
};

export const FILTER_INTERNAL_COMPANIES = {
  name: {
    value: 'name',
    type: 'string',
  },
};

export const FILTER_SCHEDULES = {
  name: {
    value: 'name',
    type: 'like',
    array: 'schedules',
    headerName: 'name',
  },
  asset: {
    value: 'assetId',
    type: 'like',
    array: 'assets',
    headerName: 'assetName',
  },
  task: {
    value: 'taskLibraryId',
    type: 'like',
    array: 'tasks',
    headerName: 'taskLibraryName',
  },
  contact: {
    value: 'supplierContactId',
    type: 'groupDropDown',
    headerName: 'supplierContactName',
    array: 'internalCompanies',
    nestedArray: 'InternalContact',
  },
  subcontract: {
    value: 'subcontract',
    type: 'boolean',
    headerName: 'haveSubcontract',
    boolArray: ['yes', 'no'],
  },
  'is active': {
    value: 'enabled',
    type: 'boolean',
    boolArray: ['yes', 'no'],
    headerName: 'active',
  },
};

export const FILTER_SCHEDULES_ADVANCE = {
  'first task': {
    value: 'firstTaskDate',
    type: 'date',
    array: 'schedules',
  },
  'last task': {
    value: 'lastTaskDate',
    type: 'date',
    array: 'schedules',
  },
};

export const FILTER_JOBS = {
  id: {
    value: 'id',
    type: 'string',
    headerName: 'id',
  },
  'due date': {
    value: 'dueDate',
    type: 'date',
    array: 'jobs',
    headerName: 'dueDate',
  },
  'visit date': {
    value: 'visitDate',
    type: 'date',
    array: 'jobs',
    headerName: 'visitDate',
  },
  'closed date': {
    value: 'closedDate',
    type: 'date',
    array: 'jobs',
    headerName: 'closedDate',
  },
  status: {
    value: 'status',
    type: 'string',
    array: 'statusList',
    label: 'name',
    headerName: 'statusName',
  },
  updated: {
    value: 'updatedAt',
    type: 'date',
    array: 'jobs',
    headerName: 'updatedAt',
  },
};
export const REACTIVE_FILTER_JOBS = {
  id: {
    value: 'id',
    type: 'string',
    headerName: 'id',
  },
  subject: {
    value: 'subject',
    type: 'string',
    headerName: 'subject',
  },
  'due date': {
    value: 'dueDate',
    type: 'date',
    array: 'jobs',
    headerName: 'dueDate',
  },
  'visit date': {
    value: 'visitDate',
    type: 'date',
    array: 'jobs',
    headerName: 'visitDate',
  },
  'closed date': {
    value: 'closedDate',
    type: 'date',
    array: 'jobs',
    headerName: 'closedDate',
  },
  status: {
    value: 'status',
    type: 'string',
    array: 'statusList',
    label: 'name',
    headerName: 'statusName',
  },
  updated: {
    value: 'updatedAt',
    type: 'date',
    array: 'jobs',
    headerName: 'updatedAt',
  },
};

export const FILTER_CONDITION = {
  Name: {
    value: 'lookupvalue',
    type: 'string',
    array: 'lookupvalues',
  },
  Order: {
    value: 'orderbyid',
    type: 'number',
    array: 'lookupvalues',
  },
};

export const FILTER_ACCOUNT_BILLING = {
  'Invoice Date': {
    value: 'invoiceAt',
    type: 'date',
    array: 'accountBillings',
  },
  'Invoice Number': {
    value: 'number',
    type: 'string',
    array: 'accountBillings',
  },
  'Payment Method': {
    value: 'paymentMethodId',
    type: 'string',
    array: 'accountBillings',
  },
  'Net Amount': {
    value: 'netValue',
    type: 'string',
    array: 'accountBillings',
  },
  'Vat Amount': {
    value: 'taxValue',
    type: 'string',
    array: 'accountBillings',
  },
  'Gross Amount': {
    value: 'grossValue',
    type: 'string',
    array: 'accountBillings',
  },
  'Created Date': {
    value: 'createdAt',
    type: 'date',
    array: 'accountBillings',
  },
  Status: {
    value: 'status',
    type: 'string',
    array: 'accountBillings',
  },
};

export const FILTER_NOTIFICATIONS = {
  fullName: {
    value: 'fullName',
    type: 'string',
    array: 'notifications',
  },
  email: {
    value: 'email',
    type: 'string',
    array: 'notifications',
  },
};

export const FILTER_STATUSCOLOR = {
  Status: {
    value: 'name',
    type: 'string',
  },
  // 'Color Code': {
  //   value: 'lookupvalue',
  //   type: 'string',
  // },
};

export const FILTER_USERS = {
  Name: {
    value: 'fullName',
    type: 'string',
    array: 'users',
  },
  Email: {
    value: 'emailAddress',
    type: 'string',
    array: 'users',
  },
};

export const FILTER_TASK_COMPLIANCES = {
  Name: {
    value: 'name',
    type: 'string',
    array: 'allTaskCompliances',
  },
  Parent: {
    value: 'parentId',
    type: 'string',
    array: 'parentTaskCompliances',
  },
};

//1453
export const FILTER_ASSET_SURVEYS = {
  'Survey No.': {
    value: 'number',
    type: 'number',
    headerName: 'number',
    array: 'assetSurveys',
  },
  'Est no. of Asset': {
    value: 'estimateNoOfAsset',
    type: 'number',
    headerName: 'estimateNoOfAsset',
    array: 'assetSurveys',
  },
  'Company Contact': {
    value: 'supplierContactId',
    type: 'groupDropDown',
    headerName: 'supplierContactName',
    array: 'internalCompanies',
    nestedArray: 'InternalContact',
  },
};

export const FILTER_SCHEDULE_ATTACHMENTS = {
  'File Name': {
    value: 'friendlyName',
    type: 'string',
    array: 'attachmentsList',
  },
};

export const FILTER_ASSET_GROUPS = {
  name: {
    value: 'name',
    type: 'like',
    headerName: 'name',
    array: 'assetGroups',
  },
};

export const FILTER_SCHEDULE_ASSET_GROUPS = {
  name: {
    value: 'name',
    type: 'like',
    headerName: 'name',
    array: 'assetGroups',
  },
};

export const FILTER_SCHEDULE_TASK = {
  task: {
    value: 'taskLibraryId',
    type: 'string',
    array: 'tasks',
  },
  contact: {
    value: 'supplierContactId',
    type: 'string',
    array: 'internalCompanies',
    nestedArray: 'InternalContact',
  },
  subcontract: {
    value: 'subcontract',
    type: 'boolean',
    boolArray: ['yes', 'no'],
  },
  'is active': {
    value: 'enabled',
    type: 'boolean',
    boolArray: ['yes', 'no'],
  },
  'first task': {
    value: 'firstTaskDate',
    type: 'date',
    array: 'schedules',
  },
  'last task': {
    value: 'lastTaskDate',
    type: 'date',
    array: 'schedules',
  },
};
export const CUSTOM_PARCEL_FILTER = [
  {
    type: 'date',
    name: 'Received At',
    placeholder: 'Received At',
    filterField: 'receivedAt',
  },
  {
    type: 'date',
    name: 'Required By',
    placeholder: 'Required By',
    filterField: 'requiredBy',
  },
  {
    type: 'date',
    name: 'Delivered At',
    placeholder: 'Delivered At',
    filterField: 'deliveredAt',
  },
  {
    type: 'date',
    name: 'Collected At',
    placeholder: 'Collected At',
    filterField: 'collectedAt',
  },
  {
    type: 'multiDropdown',
    name: 'Status',
    placeholder: 'Select Status',
    filterField: 'status',
    optionLabel: 'name',
    options: [],
  },
  {
    type: 'multiDropdown',
    name: 'Site',
    placeholder: 'Select Site',
    filterField: 'siteId',
    optionLabel: 'name',
    options: [],
    maxSelectable: 10,
    maxSelectableMsg: 'You can only select a maximum of 10 Sites',
  },
  {
    type: 'multiDropdown',
    name: 'Internal Company ',
    placeholder: 'Select Internal Company',
    filterField: 'internalCompanyId',
    optionLabel: 'name',
    options: [],
  },
];

export const CUSTOM_DELIVERY_FILTER = [
  // {
  //   type: 'defaultSearch',
  //   name: 'Search Delivery',
  //   placeholder: 'Search Delivery',
  //   filterField: 'all',
  //   isDefault: true,
  // },
];

export const CUSTOM_PLANNED_FILTER = [
  {
    type: 'date',
    name: 'Visit Date',
    placeholder: 'Visit Date',
    filterField: 'visitDate',
  },
  {
    type: 'date',
    name: 'Due Date',
    placeholder: 'Due Date',
    filterField: 'dueDate',
  },
  {
    type: 'date',
    name: 'Close Date',
    placeholder: 'Close Date',
    filterField: 'closedDate',
  },
  {
    type: 'date',
    name: 'Created At',
    placeholder: 'Created At',
    filterField: 'createdAt',
  },
  {
    type: 'multiHeadingDropdown',
    name: 'Status',
    placeholder: 'Select Status',
    filterField: 'status',
    optionLabel: 'label',
    options: [],
  },
  {
    type: 'multiDropdown',
    name: 'Site',
    placeholder: 'Select Site',
    filterField: 'siteId',
    optionLabel: 'name',
    options: [],
    maxSelectable: 10,
    maxSelectableMsg: 'You can only select a maximum of 10 Sites',
  },
  {
    type: 'multiHeadingDropdown',
    name: 'User Assignee ',
    placeholder: 'Select User Assignee',
    filterField: 'userAssigneeId',
    optionLabel: 'label',
    options: [],
    maxSelectable: 10,
    maxSelectableMsg: 'You can only select a maximum of 10 Users',
  },
];

export const JOB_CUSTOM_STATUS = {
  NOT_COMPLETE: 'NOT_COMPLETE',
  COMPLETED: 'COMPLETED',
};

export const JOB_CUSTOM_STATUS_LIST = [
  {
    label: 'Not Complete',
    value: JOB_CUSTOM_STATUS.NOT_COMPLETE,
  },
  {
    label: 'Completed',
    value: JOB_CUSTOM_STATUS.COMPLETED,
  },
];

export const CUSTOM_REACTIVE_FILTER = [
  {
    type: 'date',
    name: 'Closed At',
    placeholder: 'Closed At',
    filterField: 'closedDate',
  },
  {
    type: 'date',
    name: 'Created At',
    placeholder: 'Created At',
    filterField: 'createdAt',
  },
  {
    type: 'date',
    name: 'Due At',
    placeholder: 'Due At',
    filterField: 'dueDate',
  },
  {
    type: 'multiDropdown',
    name: 'Priority',
    placeholder: 'Priority Type',
    filterField: 'priority',
    optionLabel: 'name',
    options: [...getPriorityOptions().list.map((e) => ({ name: e.value, id: e.id }))],
  },
  {
    type: 'date',
    name: 'Reported At',
    placeholder: 'Reported At',
    filterField: 'visitDate',
  },
  {
    type: 'multiHeadingDropdown',
    name: 'Status',
    placeholder: 'Select Status',
    filterField: 'status',
    optionLabel: 'label',
    options: [],
  },
  {
    type: 'multiDropdown',
    name: 'Site',
    placeholder: 'Select Site',
    filterField: 'siteId',
    optionLabel: 'name',
    options: [],
    maxSelectable: 10,
    maxSelectableMsg: 'You can only select a maximum of 10 Sites',
  },
  {
    type: 'multiHeadingDropdown',
    name: 'User Assignee ',
    placeholder: 'Select User Assignee',
    filterField: 'userAssigneeId',
    optionLabel: 'label',
    options: [],
    maxSelectable: 10,
    maxSelectableMsg: 'You can only select a maximum of 10 Users',
  },
];

export const CUSTOM_TIMELOG_FILTER = [
  {
    type: 'date',
    name: 'Start At',
    placeholder: 'Start At',
    filterField: 'startAt',
    isDefault: true,
  },
  {
    type: 'multiDropdown',
    name: 'Site',
    placeholder: 'Select Site',
    filterField: 'siteId',
    optionLabel: 'name',
    options: [],
    isDefault: true,
    maxSelectable: 10,
    maxSelectableMsg: 'You can only select a maximum of 10 Sites',
  },
  {
    type: 'multiDropdown',
    name: 'Type',
    placeholder: 'Select Job Type',
    filterField: 'jobType',
    optionLabel: 'name',
    options: [
      { name: 'Planned', id: 1 },
      { name: 'Reactive', id: 2 },
    ],
    isDefault: true,
  },
];

export const CUSTOM_DAILY_SCHEDULE_FILTER = [
  { type: 'defaultSearch', name: 'Search Jobs', placeholder: 'Search Jobs', filterField: 'all', isDefault: true },
  {
    type: 'date',
    name: 'Visit Date',
    placeholder: 'Visit Date',
    filterField: 'visitDate',
    isDefault: true,
  },
];
