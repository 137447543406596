import nextRoutes from 'next-routes';

const routes = nextRoutes();

// main page
routes.add('main', '/', '/index');

// auth and token pages
// routes.add('auth', '/auth');
routes.add('save_tokens', '/save_tokens');
// routes.add('logout', '/logout');

// testing
routes.add('test', '/test');

// bms block
// building entity
routes.add('bms/buildings', '/bms/buildings'); // list
routes.add('bms/building_profile', '/bms/building/:bid/profile'); // profile
routes.add('bms/add_building', '/bms/building/add'); // create

// building budget entity
routes.add('bms/add_building_budget', '/bms/building/:bid/add-budget'); // create
routes.add('bms/building_budget_profile', '/bms/building-budget/:bbuuid/profile'); // profile
routes.add('bms/building_budget_tasks', '/bms/building-budget/:bbuuid/tasklist'); // tasks

// category entity
routes.add('bms/categories', '/bms/categories');
routes.add('bms/add_category', '/bms/category/add');

// category group entity
routes.add('bms/category_groups', '/bms/category-groups');
routes.add('bms/add_category_group', '/bms/category-group/add');

// building budget category entity
routes.add('bms/add_building_budget_category', '/bms/building-budget/:bbuuid/add-category'); // create
routes.add('bms/building_budget_category_profile', '/bms/building-budget-category/:bbcuuid/profile'); // profile

// threshold entity
routes.add('bms/threshold_profile', '/bms/threshold/:thuuid/profile');
routes.add('bms/add_threshold', '/bms/building-budget-category/:bbcuuid/add-threshold');

// cafm block
// general routes
routes.add('cafm/dashboard', '/cafm/dashboard');
routes.add('cafm/ppm_calendar', '/cafm/ppm-calendar');

// site entity
routes.add('cafm/admin/sites', '/cafm/admin/sites');
routes.add('cafm/admin/add_site', '/cafm/admin/site/add');
routes.add('cafm/admin/site_profile', '/cafm/admin/site/:sid/profile');

// asset entity
routes.add('cafm/admin/assets', '/cafm/admin/assets');
routes.add('cafm/admin/add_asset', '/cafm/admin/sites/:sid/add-asset');
routes.add('cafm/admin/asset_profile', '/cafm/admin/asset/:asset_id/profile');

// manufacturer entity
routes.add('cafm/admin/manufacturers', '/cafm/admin/manufacturers');
routes.add('cafm/admin/add_manufacturer', '/cafm/admin/manufacturer/add');
routes.add('cafm/admin/manufacturer_profile', '/cafm/admin/manufacturer/:manufacturer_id/profile');

// model entity
routes.add('cafm/admin/add_model', '/cafm/admin/manufacturer/:manufacturer_id/model/add');
routes.add('cafm/admin/model_profile', '/cafm/admin/model/:model_id/profile');

// task category entity
routes.add('cafm/admin/task_categories', '/cafm/admin/task-categories');
routes.add('cafm/admin/add_task_category', '/cafm/admin/task-category/add');
routes.add('cafm/admin/task_category_profile', '/cafm/admin/task-category/:task_category_id/profile');

// task entity
routes.add('cafm/admin/tasks', '/cafm/admin/tasks');
routes.add('cafm/admin/add_task', '/cafm/admin/task-category/:task_category_id/task/add');
routes.add('cafm/admin/task_profile', '/cafm/admin/task/:task_id/profile');

// schedules entity
routes.add('cafm/admin/schedules', '/cafm/admin/schedules');
routes.add('cafm/admin/add_schedule', '/cafm/admin/sites/:sid/add-maintenance-schedule');
routes.add('cafm/admin/schedule_profile', '/cafm/admin/maintenance-schedule/:schedule_id/profile');

// ppm job entity
routes.add('cafm/admin/add_ppm_job', '/cafm/admin/maintenance-schedule/:schedule_id/add-ppm-job');
routes.add('cafm/admin/ppm_job_profile', '/cafm/admin/ppm-job/:ppm_job_id/profile');

// issue categories and topics entities
routes.add('cafm/admin/issue_categories', '/cafm/admin/issue-categories');
routes.add('cafm/admin/add_issue_category', '/cafm/admin/issue-category/add');
routes.add('cafm/admin/issue_category_profile', '/cafm/admin/issue-category/:problemCategory_id/profile');
routes.add('cafm/admin/add_topic', '/cafm/admin/issue-category/:problemCategory_id/topic/add');
routes.add('cafm/admin/topic_profile', '/cafm/admin/issue-category/:problemCategory_id/topic/:problemTopic_id/profile');

// location entity
routes.add('cafm/admin/locations', '/cafm/admin/locations');
routes.add('cafm/admin/add_location', '/cafm/admin/sites/:sid/add-location');
routes.add('cafm/admin/location_profile', '/cafm/admin/location/:location_id/profile');
// if child location added from Location Profile && child location profile
routes.add('cafm/admin/add_location_child', '/cafm/admin/location/:location_id/add-location');
routes.add('cafm/admin/child_location_profile', '/cafm/admin/location/:location_id/child/profile');

// companies entity
routes.add('cafm/companies', '/cafm/admin/companies');

// auth entity
routes.add('auth/login', '/auth/login');
routes.add('auth/forgot', '/auth/forgot');
routes.add('auth/signup', '/auth/signup');
routes.add('auth/reset', '/auth/reset/:token');
routes.add('auth/activate', '/auth/activate/:token');

// Setting entity
routes.add('cafm/admin/settings', '/cafm/admin/settings');

routes.add('account/roles', '/roles');
routes.add('account/add_role', '/roles/create');
routes.add('account/edit_role', '/roles/:roleId');

routes.add('account/my_account', '/account/:uuid/:setup?');

routes.add('setup/buy_subscription', '/setup/subscription');

routes.add('cafm/admin/add_notifications', '/cafm/admin/sites/:sid/add-notifications');
routes.add('cafm/admin/notification_profile', '/cafm/admin/notification-schedule/:notification_id/profile');

routes.add('trial/free_trial_signup', '/auth/signup/free-trial');

// parent & child task compliance profile
routes.add('cafm/admin/taskcompliance_profile', '/cafm/admin/task-compliance/:taskcompliance_id/profile');
routes.add('cafm/admin/child_taskcompliance_profile', '/cafm/admin/task-compliance/:taskcompliance_id/child/profile');

routes.add('cafm/admin/asset_surveys', '/cafm/admin/asset-surveys');
routes.add('cafm/admin/asset_survey_profile', '/cafm/admin/asset-surveys/:asset_survey_id/profile');
routes.add('cafm/admin/asset_survey_setup_floor_plan', '/cafm/admin/asset-surveys/:asset_survey_id/setup-floor-plan');

routes.add('cafm/admin/asset_group_profile', '/cafm/admin/asset-group/:asset_group_id/profile');
routes.add('cafm/admin/add_asset_by_assetgroup', '/cafm/admin/asset-group/:asset_group_id/:sid/add-asset');
routes.add('cafm/admin/asset_profile_by_assetgroup', '/cafm/admin/asset/:asset_id/profile');

routes.add('cafm/admin/asset_survey_review', '/cafm/admin/asset-survey/:id/review');
routes.add('cafm/admin/asset_survey_view', '/cafm/admin/asset-survey/:id/view');

routes.add('cafm/admin/add_reactive_task', '/cafm/admin/reactive-task/add');

// user
// reports
routes.add('cafm/user/reports/planned_report', '/cafm/user/report/planned/:sid');
routes.add('cafm/user/reports/planned_report_all', '/cafm/user/report/planned');

routes.add('cafm/user/reports/reactive_report', '/cafm/user/report/reactive/:sid');
routes.add('cafm/user/reports/reactive_report_all', '/cafm/user/report/reactive');

// Daily Schedule
routes.add('cafm/user/reports/daily_schedule', '/cafm/user/report/daily-schedule');

routes.add('cafm/user/reports/show_details', '/cafm/user/report/:id/view');
routes.add('cafm/user/reports/update_details', '/cafm/user/report/:id/update');

routes.add('cafm/user/reports/reports_list_view', '/cafm/user/reports/details/:id/view');
routes.add('cafm/user/reports/reports_list_default', '/cafm/user/reports/details/default');
routes.add('cafm/user/reports/reports_list_multiple', '/cafm/user/reports/details/multiple');

routes.add('cafm/user/reports/timelog', '/cafm/user/reports/timelog');

/**
 * cafm post room
 */
routes.add('cafm/postroom/parcel', '/cafm/postroom');
routes.add('cafm/postroom/parcel_add', '/cafm/postroom/add');
routes.add('cafm/postroom/parcel_edit', '/cafm/postroom/edit/:id');
routes.add('cafm/postroom/parcel_view', '/cafm/postroom/view/:id');
routes.add('cafm/postroom/mark_as_delivered', '/cafm/postroom/mark-as-delivered/:parcelId/:type');
routes.add('cafm/postroom/parcel_settings', '/cafm/postroom/parcel-settings');
routes.add('cafm/postroom/delivery', '/cafm/postroom/delivery');
routes.add('cafm/postroom/parcel_list_delivery', '/cafm/postroom/delivery/:deliveryId');

routes.add('account/email_template', '/email-templates');
routes.add('account/email_template_view', '/email-templates/view/:id');
routes.add('account/email_template_edit', '/email-templates/edit/:id');

/**
 * task calendar
 */
routes.add('cafm/user/reports/task_calendar', '/cafm/user/reports/task-calendar');
routes.add('cafm/user/reports/task_activity_calendar', '/cafm/user/reports/task-activity-calendar');

/**
 * task Helpdesk
 */
routes.add('cafm/helpdesk/helpdesk_view', '/cafm/helpdesk');


module.exports = routes;
