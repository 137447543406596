import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { FormDatePicker, FormInput, FormQuill, SelectDropdown } from '../../form/parts';

const PPMJobEntryView = ({ t, args }) => (
  <div>
    {args?.jobData?.jobType == 2 && (
      <>
        <div className="form-group">
          <label className="col-sm-4 control-label">{t('Site')}</label>

          <div className="col-sm-8">
            <Field
              name="siteId"
              component={SelectDropdown}
              drpName="siteId"
              options={args.siteList}
              _optionLabel="name"
              _optionValue="id"
              // isDisabled={args.subcontract !== null && args.subcontract}
              placeholder={t('Choose Site')}
            />
          </div>
        </div>
        <div className="form-group">
          <label className="col-sm-4 control-label">{t('Location')}</label>

          <div className="col-sm-8">
            <Field
              name="locationId"
              component={SelectDropdown}
              drpName="locationId"
              options={args.locationList}
              _optionLabel="name"
              _optionValue="id"
              isDisabled={args.siteId === null}
              placeholder={t('Choose Location')}
            />
          </div>
        </div>
        <div className="form-group">
          <label className="col-sm-4 control-label">{t('Subject')}</label>
          <div className="col-sm-8">
            <Field name="subject" component={FormInput} addclass="col-sm-12" placeholder={t('Choose subject')} />
          </div>
        </div>

        <div className="form-group">
          <label className="col-sm-4 control-label">{t('Description')}</label>

          <div className="col-sm-8">
            <Field
              name="description"
              component={FormQuill}
              addclass="col-sm-12"
              placeholder={t('Enter Description')}
              bounds=".description-container"
            />
          </div>
        </div>
      </>
    )}

    <div className="form-group">
      <label className="col-sm-4 control-label">{t('Status')}</label>

      <div className="col-sm-8">
        <Field
          name="status"
          component={SelectDropdown}
          drpName="status"
          options={args.statusList}
          placeholder={t('Status')}
        />
      </div>
    </div>

    {args?.jobData?.jobType == 2 && (
      <div className="form-group">
        <label className="col-sm-4 control-label">{t('Priority')}</label>

        <div className="col-sm-8">
          <Field
            name="priority"
            component={SelectDropdown}
            drpName="priority"
            options={args.priorityList}
            _optionLabel="value"
            _optionValue="id"
            placeholder={t('Choose Priority')}
          />
        </div>
      </div>
    )}

    <div className="form-group">
      <label className="col-sm-4 control-label">{t('Visit date')}</label>

      <div className="col-sm-8 full_datepicker">
        <Field
          name="visitDate"
          placeholder={t('Choose visit date')}
          component={FormDatePicker}
          showTimeSelect={true}
          showTimeInput={true}
          timeInputLabel={'Time:'}
        />
      </div>
    </div>

    <div className="form-group">
      <label className="col-sm-4 control-label">{t('Due At')}</label>

      <div className="col-sm-8 full_datepicker">
        <Field
          name="dueDate"
          placeholder={t('Choose due date')}
          component={FormDatePicker}
          showTimeSelect={true}
          showTimeInput={true}
          timeInputLabel={'Time:'}
        />
      </div>
    </div>

    {/* <div className="form-group">
      <label className="col-sm-4 control-label">{t('Closed date')}</label>

      <div className="col-sm-8 full_datepicker">
        <Field
          name="closedDate"
          placeholder={t('Choose closed date')}
          component={FormDatePicker}
          showTimeSelect={true}
          showTimeInput={true}
          timeInputLabel={'Time:'}
        />
      </div>
    </div> */}

    <div className="form-group">
      <label className="col-sm-4 control-label">{t('Internal User')}</label>

      <div className="col-sm-8">
        <Field
          name="userAssigneeId"
          component={SelectDropdown}
          drpName="userAssigneeId"
          options={args.userList}
          _optionLabel="fullName"
          _optionValue="id"
          isDisabled={args.subcontract !== null && args.subcontract}
          placeholder={t('Choose Internal User')}
        />
      </div>
    </div>

    <div className="form-group">
      <div className="col-sm-6 col-sm-offset-4 pull-left">
        <span>{t('or')}</span>
      </div>
    </div>

    <div className="form-group">
      <label className="col-sm-4 control-label">
        {t(args?.jobData?.jobType == 2 ? 'Subcontractor' : 'Carried out by')}
      </label>

      <div className="col-sm-8">
        <Field
          name="supplierCompanyId"
          component={SelectDropdown}
          drpName="supplierCompanyId"
          options={args.companyList}
          isDisabled={args.subcontract !== null && !args.subcontract}
          placeholder={t('Choose Subcontractor')}
        />
      </div>
    </div>

    <div className="form-group">
      <label className="col-sm-4 control-label">{t('Contact')}</label>

      <div className="col-sm-8">
        <Field
          name="supplierContactId"
          component={SelectDropdown}
          drpName="supplierContactId"
          options={args.contactList}
          _optionLabel="fullName"
          isDisabled={args.subcontract !== null && !args.subcontract}
          placeholder={t('Choose Subcontractor Contact')}
        />
      </div>
    </div>
  </div>
);

const ppmJobProps = PropTypes.shape({
  userList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  subcontract: PropTypes.bool.isRequired,
  companyList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  locationList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  contactList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  statusList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  siteList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  scheduleTaskList: PropTypes.arrayOf(PropTypes.shape()),
  jobData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}).isRequired;

PPMJobEntryView.propTypes = {
  args: ppmJobProps,
  t: PropTypes.func.isRequired,
};

PPMJobEntryView.defaultProps = {
  args: {
    userList: [],
    subcontract: false,
    siteId: null,
    companyList: [],
    contactList: [],
    statusList: [],
    scheduleTaskList: [],
    siteList: [],
    locationList: [],
  },
};

export default PPMJobEntryView;
